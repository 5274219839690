<template>
  <div>
    <h4>陕西西咸新区服贸协会组织架构</h4>
    <h5>Organizational structure</h5>
    <img src="../../assets/image/zuzhijiagou.png">
  </div>
</template>

<script>

export default {
  name: "organizationalStructure",
  data() {
    return {}
  },
  components: {

  }
}
</script>

<style scoped>
img {
  width: 100%;
}
</style>